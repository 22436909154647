import React, { useState } from "react"
import { graphql } from "gatsby"
import { nanoid } from "nanoid"

import { Section, Container } from "../../anti/grid/grid"
import { Card } from "../../anti/card/card"
import { CardSlider } from "../../anti/card-slider/card-slider"
// import Img from "gatsby-image"

export const AcfGalleryBlock = graphql`
  fragment AcfGalleryBlock on WordPress_AcfAcfgalleryBlock {
    name
    attributes {
      className
    }
    acfBlockGallery {
      bgColor
      images {
        sourceUrl(size: MEDIUM_LARGE)
      }
    }
  }
`

export const AcfGallery = ({ data, className }) => {
  const [id] = useState(nanoid)
  const acfBlockGallery = data.acfBlockGallery

  return (
    <div
      className={`${acfBlockGallery &&
        acfBlockGallery.bgColor} ${data.attributes &&
        data.attributes.className} ${className ? className : ""}`}
    >
      <Container className="gallery-show-mobile">
        <CardSlider
          // fade
          noGutter
          showInitial={1}
          showLgDown={1}
          showMdDown={1}
          showSmDown={1}
          visibleInitial={false}
          arrowsSmDown={true}
          arrowsLgDown={true}
          arrowsInitial={true}
          arrowsMdDown={true}
        >
          {acfBlockGallery?.images &&
            acfBlockGallery?.images?.map((item, i) => {
              return (
                <Card
                  variant="boxless"
                  img={item.sourceUrl}
                  imgHeight="h-ratio-2-1"
                  key={`${id}${i}`}
                  className="gallery-card"
                />
              )
            })}
        </CardSlider>
      </Container>
    </div>
  )
}
