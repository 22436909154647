import React from "react"
import { graphql } from "gatsby"

import { replaceUnicode } from "../../anti/utils/utils"

export const CoreHeadingBlock = graphql`
  fragment CoreHeadingBlock on WordPress_CoreHeadingBlock {
    attributes {
      ... on WordPress_CoreHeadingBlockAttributes {
        align
        className
        content
        level
      }
    }
  }
`

// prettier-ignore
export const CoreHeading = ({ data }) => {
  const heading = level => {
    switch (level) {
      case 1:
        return (
          <h1 className={`${data.attributes.className} text-${data.attributes.align && data.attributes.align}`} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h1>
        )
      case 2:
        return (
          <h2 className={`${data.attributes.className} text-${data.attributes.align && data.attributes.align}`} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h2>
        )
      case 3:
        return (
          <h3 className={`${data.attributes.className} text-${data.attributes.align && data.attributes.align}`} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h3>
        )
      case 4:
        return (
          <h4 className={`${data.attributes.className} text-${data.attributes.align && data.attributes.align}`} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h4>
        )
      case 5:
        return (
          <h5 className={`${data.attributes.className} text-${data.attributes.align && data.attributes.align}`} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h5>
        )
      case 6:
        return (
          <h6 className={`${data.attributes.className} text-${data.attributes.align && data.attributes.align}`} dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h6>
        )
    }
  }

  return heading(data.attributes.level)
}
